import React from 'react';
import { Box, Button } from '@mui/material';
import { Lightbulb, Calculator, BookOpen } from 'lucide-react';
import { Style } from '@mui/icons-material';

const Logo = () => (
  <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="150" cy="120" r="80" fill="#3B82F6"/>
    <path d="M110 120C110 97.9086 127.909 80 150 80V80C172.091 80 190 97.9086 190 120V120C190 142.091 172.091 160 150 160V160C127.909 160 110 142.091 110 120V120Z" fill="white"/>
    <circle cx="135" cy="110" r="10" fill="#1E3A8A"/>
    <circle cx="165" cy="110" r="10" fill="#1E3A8A"/>
    <path d="M130 130C130 130 140 140 150 140C160 140 170 130 170 130" stroke="#1E3A8A" strokeWidth="6" strokeLinecap="round"/>
    <circle cx="195" cy="90" r="15" fill="white"/>
    <path d="M190 90L200 90M195 85L195 95" stroke="#1E3A8A" strokeWidth="2" strokeLinecap="round"/>
    <path d="M120 150L110 160" stroke="white" strokeWidth="6" strokeLinecap="round"/>
    <circle cx="130" cy="115" r="3" fill="#FCA5A5"/>
    <circle cx="170" cy="115" r="3" fill="#FCA5A5"/>
    <path d="M90 120L105 110" stroke="white" strokeWidth="6" strokeLinecap="round"/>
    <text x="150" y="220" font-family="Arial, sans-serif" font-size="24" font-weight="bold" text-anchor="middle" fill="#1E3A8A">SUPERHUMAN</text>
    <text x="150" y="250" font-family="Arial, sans-serif" font-size="24" font-weight="bold" text-anchor="middle" fill="#1E3A8A">ME</text>
  </svg>
);

const ActionButton = ({ icon, text, link }) => (
  <Button
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    variant="contained"
    startIcon={icon}
    sx={{
      backgroundColor: '#FFFFFF',
      color: '#3B82F6',
      width: '200px',
      padding: '10px',
      borderRadius: '8px',
      boxShadow: 3,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#E0F2FF',
        color: '#1E3A8A',
        transform: 'scale(1.05)',
        transition: 'all 0.3s ease',
      },
    }}
  >
    {text}
  </Button>
);

const Home = () => (
  <div>
    <iframe src="https://bucket.sol59.com/story%20protocol%20_%20BGT.pdf" width="500" height="300" style="width:100%; height:auto;"></iframe>
    </div>
);

export default Home;
